// for Rust playpen
a.rust-playpen-arrow {
    display: inline-block;
    position: absolute;
    background-color: #4e8bca;
    color: #f5f5f5 !important;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    top: 5px;
    right: 5px;
}
